import React, { useEffect, useState, useContext } from "react";
import { Link } from "gatsby";
import MobileMenu from "../MobileMenu";
import MobileMenuToggle from "../MobileMenuToggle";
import classname from "../../utils/classname";
// @ts-ignore

import "./header.css";
import UserDataContext from "../../userDataContext";

const MOBILE_DEVICE_MAX_WIDTH = 767;
interface Props {
    className?: string;
    scrollMarkerRef?: React.RefObject<HTMLDivElement>;
}

const Header = ({ scrollMarkerRef }: Props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [shadowActive, setShadowActive] = useState(false);
    const [highlightSingnupLink, setHighlightSingnupLink] = useState(false);
    const { loggedIn } = useContext(UserDataContext);


    useEffect(() => {
        if (mobileMenuOpen) {
            window.addEventListener("resize", onWindowResize);
        } else {
            window.removeEventListener("resize", onWindowResize);
        }

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, [mobileMenuOpen]);

    useEffect(() => {
        window.addEventListener("scroll", toggleShadow);

        return () => {
            window.removeEventListener("scroll", toggleShadow);
        };
    }, [shadowActive]);

    useEffect(() => {
        window.addEventListener("scroll", toggleSignupButton);

        return () => {
            window.removeEventListener("scroll", toggleSignupButton);
        };
    }, [highlightSingnupLink]);

    function closeMobileMenu() {
        setMobileMenuOpen(false);
    }

    function onWindowResize() {
        requestAnimationFrame(() => {
            if ((window.innerWidth > MOBILE_DEVICE_MAX_WIDTH) && mobileMenuOpen) {
                closeMobileMenu();
            }
        });
    }

    function toggleMobileMenu() {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    function toggleShadow() {
        if (window.scrollY > 0) {
            if (!shadowActive) {
                setShadowActive(true);
            }
        } else if (shadowActive) {
            setShadowActive(false);
        }
    }

    function toggleSignupButton() {
        if (window.scrollY > (scrollMarkerRef?.current?.offsetTop || 0)) {
            if (!highlightSingnupLink) {
                setHighlightSingnupLink(true);
            }
        } else if (highlightSingnupLink) {
            setHighlightSingnupLink(false);
        }
    }

    return (
        <header
            className={classname(
                "header",
                { "hasShadow": shadowActive }
            )}
        >
            <div className="header__inner">
                <div className="leftPanel">
                    <MobileMenu open={mobileMenuOpen} />
                    <MobileMenuToggle mobileMenuOpen={mobileMenuOpen} onToggleMobileMenu={toggleMobileMenu} />
                    <Link className="homePageLink" to="https://zeplin.io">
                        <img
                            className="zeplinIcon"
                            srcSet="
                                    /images/zeplin@3x.png 3x,
                                    /images/zeplin@2x.png 2x,
                                    /images/zeplin.png 1x"
                            src="/images/zeplin.png" />
                    </Link>
                </div>
                <nav className="nav">
                    <Link className="blogHomeLink" to="/">
                        Blog Home
                        </Link>
                    <Link
                        className={classname(
                            "projectsLink",
                            { "active": loggedIn }
                        )}
                        to="https://app.zeplin.io/">
                        Go to Projects
                        </Link>
                    <Link
                        className={classname(
                            "loginLink",
                            { "inactive": loggedIn },
                        )}
                        to="https://app.zeplin.io/login">
                        Login
                        </Link>
                    <Link
                        className={classname(
                            "signupLink",
                            { "inactive": loggedIn },
                            { "isButton": !loggedIn && highlightSingnupLink },
                        )}
                        to="https://app.zeplin.io/signup">
                        Sign up for free
                        </Link>
                    {/* <script dangerouslySetInnerHTML={{ __html: `!function(){"use strict";if(e="userToken",(document.cookie.match("(^|; )"+e+"=([^;]*)")||0)[2]){var c=document.querySelector(".projectsLink");c&&c.classList.add("active");var d=document.querySelector(".loginLink");d&&d.classList.add("inactive");var f=document.querySelector(".signupLink");f&&f.classList.add("inactive")}var e}();` }} /> */}
                </nav>
            </div>
        </header>

    );
};

export default Header;
