import { LinkedData } from "../../types/types"

type Schema = {
    "@context": string;
    "@type": string;
    "mainEntityOfPage"?: {
        "@type": string;
        "@id"?: string;
    };
    "headline": string;
    "image"?: string;
    "datePublished": string;
    "dateModified": string;
    "author"?: {
        "@type": string;
        "name"?: string;
        "alternateName"?: string;
        "url"?: string;
    };
    "publisher": {
        "@type": string;
        "name": string;
        "logo": {
            "@type": string;
            "url": string;
        };
    };
    "@graph"?: {
        "@type": string;
        "mainEntity": {
            "@type": string;
            "name": string;
            "acceptedAnswer": {
                "@type": string;
                "text": string;
            }[];
        }
    }[];
}

export default function(linkedData : LinkedData | undefined, postLinkUrl : string) {
    if (typeof linkedData !== "object") return;

    const _publishedAt = new Date(linkedData.datePublished);
    const _dateModified = new Date(linkedData.dateModified);

    const ld : Schema = {
        "@context": "https://schema.org",
        "@type": linkedData.type || "WebPage",
        "headline": linkedData.headline,
        "datePublished": _publishedAt.toLocaleDateString("en-CA"),
        "dateModified": _dateModified.toLocaleDateString("en-CA"),
        "publisher": {
            "@type": "Organization",
            "name": "Zeplin",
            "logo": {
                "@type": "ImageObject",
                "url": "https://zeplin.io/static/favicon-256x256.png"
            }
        }
    };

    if (linkedData.type !== "WebPage" && postLinkUrl) {
        ld.mainEntityOfPage = {
            "@type": "WebPage",
        };

        if (postLinkUrl) {
            ld.mainEntityOfPage["@id"] = postLinkUrl;
        }
    }

    if (linkedData.author) {
        ld.author = {
            "@type": "Person",
        };

        if (linkedData.author.name) {
            ld.author.name = linkedData.author.name;
        }

        if (linkedData.author.alternateName) {
            ld.author.alternateName = linkedData.author.alternateName;
        }

        if (linkedData.author.url) {
            ld.author.url = linkedData.author.url;
        }
    }

    if (linkedData.image) {
        ld.image = linkedData.image;
    }

    if (Array.isArray(linkedData.graph)) {
        ld["@graph"] = linkedData.graph.map((entry) => ({
            "@type": entry.type,
            mainEntity: entry.entries?.map(({name, acceptedAnswer}) => ({
                "@type": "Question",
                name: name,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: acceptedAnswer,
                }
            }))
        }));
    }

    return JSON.stringify(ld);
}