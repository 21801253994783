import React from "react";

import classname from "../../utils/classname";

import "./mobileMenuToggle.css";

export default function MobileMenuToggle({ onToggleMobileMenu, mobileMenuOpen }) {
    return (
        <div className={classname("mobileMenuToggle", { mobileMenuOpen })} onClick={onToggleMobileMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>
    );
}
