import React from "react";
import { Link } from "gatsby";

import "./footer.css";

// TODO replace blog urls with in-house blog urls
const Footer = () => {
    return (
        <footer>
            <div className="zeplinLogoContainer">
                <img
                    className="zeplinIcon"
                    srcSet="
                        /images/zeplin@3x.png 3x,
                        /images/zeplin@2x.png 2x,
                        /images/zeplin.png 1x"
                    src="/images/zeplin.png"/>
            </div>
            <nav>
                <div className="navGroup zeplin">
                    <h5>Zeplin</h5>
                    <ul>
                        <li><Link to="https://zeplin.io/about">About</Link></li>
                        <li><Link to="https://zeplin.io/zam">Agency membership</Link></li>
                        <li><Link to="https://boards.greenhouse.io/zeplin" rel="external noopener" target="_blank">Careers</Link></li>
                        <li><Link to="https://zeplin.io/terms">Terms</Link></li>
                        <li><Link to="https://zeplin.io/privacy">Privacy</Link></li>
                    </ul>
                </div>
                <div className="navGroup features">
                    <h5>Features</h5>
                    <ul>
                        <li><Link to="https://zeplin.io/features/connected-components">Connected Components</Link></li>
                        <li><Link to="https://docs.zeplin.dev" rel="external noopener" target="_blank">Zeplin API</Link></li>
                        <li><Link to="https://blog.zeplin.io/zeplin-for-jira-is-here-enabling-two-way-collaboration-8f59c03a5faf" rel="external noopener" target="_blank">Zeplin for Jira</Link></li>
                        <li><Link to="https://blog.zeplin.io/announcing-global-styleguides-connecting-design-systems-to-engineering-65ad22bd0076" rel="external noopener" target="_blank">Global Styleguides</Link></li>
                    </ul>
                </div>
                <div className="navGroup designApps">
                    <h5>Design apps</h5>
                    <ul>
                        <li><Link to="https://support.zeplin.io/quick-start/exporting-designs-from-sketch" rel="external noopener" target="_blank">Sketch integration</Link></li>
                        <li><Link to="https://zeplin.io/figma">Figma integration</Link></li>
                        <li><Link to="https://blog.zeplin.io/zeplin-now-integrates-with-adobe-xd-cc-726c8a4b2f22" rel="external noopener" target="_blank">Adobe XD integration</Link></li>
                        <li><Link to="https://support.zeplin.io/quick-start/exporting-designs-from-photoshop" rel="external noopener" target="_blank">Photoshop integration</Link></li>
                    </ul>
                </div>
                <div className="navGroup collaborationApps">
                    <h5>Collaboration apps</h5>
                    <ul>
                        <li><Link to="https://support.zeplin.io/zeplin-101/keep-up-to-date-on-your-projects-with-the-slack-integration" rel="external noopener" target="_blank">Slack integration</Link></li>
                        <li><Link to="https://blog.zeplin.io/zeplin-and-trello-sitting-in-a-tree-roo-c6e0cfed28e6" rel="external noopener" target="_blank">Trello integration</Link></li>
                        <li><Link to="https://blog.zeplin.io/zeplin-for-jira-is-here-enabling-two-way-collaboration-8f59c03a5faf" rel="external noopener" target="_blank">Jira integration</Link></li>
                        <li><Link to="https://blog.zeplin.io/stay-in-the-loop-with-the-microsoft-teams-integration-49f7cdf25a0a" rel="external noopener" target="_blank">MS Teams integration</Link></li>
                    </ul>
                </div>
                <div className="navGroup learn">
                    <h5>Learn</h5>
                    <ul>
                        <li><Link to="https://support.zeplin.io/" rel="external noopener" target="_blank">Support articles</Link></li>
                        <li><Link to="https://blog.zeplin.io/" rel="external noopener" target="_blank">Blog</Link></li>
                        <li><Link to="https://www.facebook.com/groups/getraven/" rel="external noopener" target="_blank">Facebook <span className="community">community</span></Link></li>
                        <li><Link to="https://twitter.com/zeplin" rel="external noopener" target="_blank">Twitter</Link></li>
                    </ul>
                </div>
                <div className="navGroup handyLinks">
                    <h5>Handy links</h5>
                    <ul>
                        <li key="downloadMacApp"><Link to="https://zpl.io/download-mac" download>Download the Mac app</Link></li>
                        <li key="downloadWindowsApp"><Link to="https://zpl.io/download-windows-64" download>Download the Windows app</Link></li>
                        <li><Link to="https://app.zeplin.io">Go to the web app</Link></li>
                    </ul>
                </div>
            </nav>
        </footer>
    );
};

export default Footer;
