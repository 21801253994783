import React, { Component } from "react";
import classname from "../../utils/classname";

import "./mobileMenu.css";

export default class MobileMenu extends Component {
    constructor() {
        super();
        this.onResize = this.onResize.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.state = {
            height: "100vh"
        };
    }

    onTransitionEnd() {
        if (this.props.open) {
            document.body.classList.add("fadeContent");
        }
    }

    onResize() {
        if (this.state.height !== (window.innerHeight) + "px" && this.props.open) {
            this.setState({
                height: window.innerHeight + "px"
            });
        }
    }

    componentDidMount() {
        if (/Mobi/.test(navigator.userAgent)) {
            window.addEventListener("resize", this.onResize);
            this.setState({
                height: window.innerHeight + "px"
            });
        }
    }

    componentWillReceiveProps({ open }) {
        if (!this.props.open && open && this.state.height !== (window.innerHeight) + "px" && /Mobi/.test(navigator.userAgent)) {
            this.setState({
                height: window.innerHeight + "px"
            });
        }
    }

    componentDidUpdate() {
        document.body.style.overflow = this.props.open ? "hidden" : "";
        if (!this.props.open) {
            document.body.classList.remove("fadeContent");
        }
    }

    componentWillUnmount() {
        if (/Mobi/.test(navigator.userAgent)) {
            window.removeEventListener("resize", this.onResize);
        }
    }

    render() {
        return (
            <div className={classname("mobileMenu", { open: this.props.open })}
                style={({ height: this.state.height })}
                onTransitionEnd={this.onTransitionEnd}>
                <div className="menuElements">
                    <a className="newsletterLink menuButton" href="/subscribe" target="_blank">
                        Subscribe to Newsletter
                    </a>
                </div>
            </div>
        );
    }
}
