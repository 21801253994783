import * as React from "react";

import Header from "../Header";
import Footer from "../Footer";

import "./layout.css";
import "../../prism/prism.css";
import "../../zeplin.css";

interface Props {
    children: (JSX.Element | undefined)[];
    className?: string;
    scrollMarkerRef?: React.RefObject<HTMLDivElement>;
}

const Layout = ({ children, className: cssClass, scrollMarkerRef }: Props) => (
    <div className="layout">
        <Header scrollMarkerRef={scrollMarkerRef} />
        <main className={cssClass}>{children}</main>
        <Footer/>
    </div>
);

export default Layout;
