/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { LinkedData } from "../../types/types"
import getLinkedData from "./ld-json";

interface Props {
    description?: string;
    lang?: string;
    meta?: Meta[];
    title: string;
    postSlug?: string;
    image?: string;
    linkedData?: LinkedData;
}

interface Meta {
    name: string;
    content: string;
}

function Seo({
    description = "",
    lang = "en",
    meta = [],
    title,
    postSlug,
    image = "https://cdn.zeplin.io/assets/lp/img/favicon/228x228.png",
    linkedData,
}: Props) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata?.title;
    const postLinkUrl = postSlug ? `${site.siteMetadata?.siteUrl}/${postSlug}` : site.siteMetadata?.siteUrl

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
            link={[
                {
                    rel: "canonical",
                    href: postLinkUrl
                }
            ]}
            meta={[
                {
                    name: "robots",
                    content: "index, follow"
                },
                {
                    property: `title`,
                    content: title
                },
                {
                    name: `referrer`,
                    content: "always"
                },
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    name: `keywords`,
                    content: "DESIGN, DEVELOPMENT, COLLABORATION"
                },
                {
                    name: `theme-color`,
                    content: "#000000"
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    property: `og:url`,
                    content: postLinkUrl
                },
                {
                    property: `og:image`,
                    content: image
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {
                    name: `twitter:image:src`,
                    content: image
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`
                },
                {
                    property: `og:site_name`,
                    content: "Zeplin Gazette"
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``
                },
                {
                    name: `twitter:image`,
                    content: image
                }
            ].concat(meta)}
            script={[{ 
                type: 'application/ld+json', 
                innerHTML: getLinkedData(linkedData, postLinkUrl)
            }]}
        />
    );
}

export default Seo;
